const galeria = document.querySelector('.galeria');
const imagenGrande = document.querySelector('.imagen-grande img');
const miniaturas = document.querySelectorAll('.miniatura');

miniaturas.forEach((miniatura) => {
  miniatura.addEventListener('click', () => {
    const srcset = miniatura.querySelector('img').srcset;
    const sources = srcset.split(', ');
    //const highResSource = sources.find((source) => source.includes('high'));
    imagenGrande.srcset = srcset;
    imagenGrande.sizes = '(max-width: 600px) 300px, 600px';
  });
});